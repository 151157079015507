@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer base {
  html {
    font-family: "Fira+Sans", system-ui, sans-serif;
  }
}

@layer utilities {
    @variants responsive {
        .masonry {
            column-gap: 1.5em;
            column-count: 1;
        }
        .masonry-sm {
            column-gap: 1.5em;
            column-count: 2;
        }
        .masonry-md {
            column-gap: 1.5em;
            column-count: 4;
        }
        .break-inside {
            break-inside: avoid;
        }

    }
}